<template>
  <div>
    <big-title>
      Segundo Factor de <span>Autenticación</span>.
    </big-title>

    <vx-card >
      <div class="vx-row">
        <div class="vx-col xxl:w-2/3 w-full">
          <p>
            El Segundo Factor de Autenticación es una de las mejores formas de garantizar que tu cuenta se mantenga segura.
            Al activarlo, aseguras que sólo tú autorices tus operaciones. En caso de que pierdas tu contraseña, tu cuenta permanecerá segura.
          </p>
        </div>
      </div>
    </vx-card>

    <!-- ENABLED CARD -->
    <vx-card class="mt-5" v-if="showCurrentStatus && enabled">
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <p class="card-sub-title">Tu Segundo Factor de Autenticación se encuentra <span>habilitado</span>.</p>
        </div>
      </div>

      <!-- FLUJO DEFAULT -->
      <div class="vx-row" v-if="onDefaultFlow">
        <div class="vx-col w-full">
          <p class="mb-4">Para deshabilitar tu Segundo Factor de Autenticación es necesario que introduzcas tu
            contraseña y <strong>el código de 6 dígitos generado por el autenticador</strong>.
            ¿No cuentas con los 6 dígitos del autenticador?
            <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="showSecretCodeFlow">Da clic aquí.</router-link></span>
          </p>

          <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-2 mb-3" color="danger">
            <span class="font-regular">{{errorMssg}}</span>
          </vs-alert>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Contraseña"
                type="password"
                v-validate="'required'"
                v-model.lazy="currentPassword"
                :danger="hasError('currentPassword')"
                :danger-text="errorText('currentPassword')"
                :success="isSuccess('currentPassword')"
                name="currentPassword"/>
            </div>
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Código de 6 dígitos"
                type="text"
                maxlength="6"
                v-model.lazy="authCode"
                v-validate="'required|numeric'"
                :danger="hasError('authCode')"
                :danger-text="errorText('authCode')"
                :success="isSuccess('authCode')"
                name="authCode"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mt-2">
              <div class="flex flex-wrap items-center">
                <vs-button @click="disable2Fa" :disabled="getting">Deshabilitar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN FLUJO DEFAULT -->

      <!-- FLUJO POR CODIGO SECRETO -->
      <div class="row" v-if="onSecretCodeFlow">
        <div class="vx-col w-full">
          <p class="mb-4">
            Ya que no cuentas con tu Segundo Factor de Autenticación, ingresa la <strong>llave de 16 caracteres</strong>
            que guardaste previamente al habilitar tu Segundo Factor de Autenticación. En caso de que hayas perdido tu llave
            de 16 caracteres<span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="showRecoveryEmailFlow">da clic aquí.</router-link></span>
          </p>

          <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-2 mb-3" color="danger">
            <span class="font-regular">{{errorMssg}}</span>
          </vs-alert>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Contraseña"
                type="password"
                v-validate="'required'"
                v-model.lazy="currentPassword"
                :danger="hasError('currentPassword')"
                :danger-text="errorText('currentPassword')"
                :success="isSuccess('currentPassword')"
                name="currentPassword"/>
            </div>
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Llave de 16 caracteres"
                type="text"
                maxlength="16"
                v-model.lazy="prevSecretCode"
                v-validate="'required'"
                :danger="hasError('prevSecretCode')"
                :danger-text="errorText('prevSecretCode')"
                :success="isSuccess('prevSecretCode')"
                name="prevSecretCode"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mt-2">
              <div class="flex flex-wrap items-center">
                <vs-button @click="disable2FaWithSecretCode" :disabled="getting">Deshabilitar</vs-button>
                <vs-button class="ml-5" type="border" @click="showDefaultFlow" :disabled="getting">Sí tengo mi segundo factor de autenticación</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN FLUJO POR CODIGO SECRETO -->

      <!-- FLUJO DE RECUPERACION POR EMAIL -->
      <div class="row" v-if="onRecoveryEmailFlow">
        <div class="vx-col w-full">
          <p class="mb-5">
            Para deshabilitar tu Segundo Factor de Autenticación, ingresa tu contraseña y presiona el botón <strong class="underline">"Enviar código"</strong>, ingresa el código de 6 dígitos que recibiste en tu correo.
          </p>
          <p v-if="emailSent" class="mb-5">
            ¿No recibiste el correo?  <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="sendCodeEmail">Enviar de nuevo correo con código</router-link></span>.
          </p>

          <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-2 mb-5" color="danger">
            <span class="font-regular">{{errorMssg}}</span>
          </vs-alert>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Contraseña"
                type="password"
                v-validate="'required'"
                v-model.lazy="currentPassword"
                :danger="hasError('currentPassword')"
                :danger-text="errorText('currentPassword')"
                :success="isSuccess('currentPassword')"
                name="currentPassword"/>
            </div>
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Código de 6 digitos enviado por correo"
                type="text"
                maxlength="16"
                v-model.lazy="emailCode"
                v-validate="'required'"
                :danger="hasError('emailCode')"
                :danger-text="errorText('emailCode')"
                :success="isSuccess('emailCode')"
                name="emailCode"/>
            </div>
          </div>

          <!-- TODO-DELETE-IA 18/04/2023 -->
          <!-- Person type 0 y 4 -->
          <!-- <s-l-r-person0-and4
            v-if="isPersonType0And4"
            ref="currentForm"
            :states="states"
            :banks="banks" /> -->

          <!-- Person type 1 -->
          <!-- <s-l-r-person1
            v-if="isPersonType1"
            ref="currentForm"
            :states="states"
            :banks="banks" /> -->

          <!-- Person type 2 -->
          <!-- <s-l-r-person2
            v-if="isPersonType2"
            ref="currentForm"
            :banks="banks" /> -->

          <!-- Person type 2 -->
          <!-- <s-l-r-person3
            v-if="isPersonType3"
            ref="currentForm"
            :states="states"
            :banks="banks" /> -->

          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button v-if="!emailSent" color="dark" @click="sendCodeEmail" :disabled="getting">Enviar código</vs-button>
                <vs-button v-else @click="disable2FaWithEmailCode" :disabled="getting">Deshabilitar</vs-button>
                <!-- <vs-button @click="checkRecoveryForm" :disabled="getting">Validar respuestas</vs-button> -->
                <!-- <vs-button class="ml-5" type="border" @click="showDefaultFlow" :disabled="getting">Volver a las opciones principales.</vs-button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN FLUJO DE RECUPERACION SECRETO -->

    </vx-card>
    <!-- END ENABLED CARD -->

    <!-- DISABLE CARD -->
    <vx-card class="mt-5" v-if="showCurrentStatus && !enabled">
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <p class="card-sub-title"><span>Habilita</span> tu Segundo Factor de Autenticación en 3 pasos.</p>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col lg:w-1/3 w-full mt-5 mb-5">
          <p class="mb-2">
            <strong>1. Instala una aplicación de autenticación de dos factores</strong><br>
            Te recomendamos <strong>Google Authenticator</strong> descárgala en tu smarthphone o tablet.
          </p>
          <div class="flex mt-5">
            <a
              class="mr-5"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              rel="noopener noreferrer"
              target="_blank">
                <img alt="" :src="playStoreLogo">
              </a>
            <a
              href="https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8"
              rel="noopener noreferrer"
              target="_blank">
                <img alt="" :src="appleStoreLogo">
              </a>
          </div>
        </div>
        <div class="vx-col lg:w-1/3 w-full mt-5 mb-5">
          <p class="mb-2">
            <strong>2. Configura con RedGirasol</strong><br>
            Escanea el siguiente código QR o escribe la siguiente llave de 16 caracteres <strong>{{secret}}</strong> para configurar la aplicación con RedGirasol.
          </p>
          <div class="flex mt-5">
            <img :src="qr" style="width:110px;" alt="qr">
            <div class="vx-row mt-5">
              <div class="vx-col w-full">
                <div class="flex mt-5">
                  <div class="stp-section-container">
                    <span class="mx-4 bold">{{secret}}</span>
                  </div>
                  <vs-button size="small" id="copy_accounts_btn" class=" ml-2 pt-3 pb-2 px-3" color="primary"  @click.stop="copyToClipboard">
                    <feather-icon icon="FileTextIcon" svg-classes="h-4 w-4" />
                  </vs-button>
                </div>
                <vs-alert class="mt-2 pt-1 pb-1" :active.sync="copied" color="success" closable>Llave copiada.</vs-alert>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col lg:w-1/3 w-full mt-5 mb-5">
          <p class="mb-2">
            <strong>3. Ingresa el código de 6 dígitos</strong><br>
            Copia el código que aparece en tu aplicación en el siguiente espacio y da click en Habilitar.
          </p>
          <div class="flex mt-5">
            <vs-input
              type="text"
              maxlength="6"
              v-model.lazy="authCode"
              v-validate="'required|numeric'"
              :danger="hasError('authCode')"
              :danger-text="errorText('authCode')"
              :success="isSuccess('authCode')"
              placeholder="Código de 6 dígitos"
              name="authCode"/>
            <vs-button class="ml-2" @click="enable2Fa" :disabled="getting">Habilitar</vs-button>
          </div>
          <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-1 mb-5" color="danger">
            <span class="font-regular">{{errorMssg}}</span>
          </vs-alert>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-divider color="#C8C8C8"></vs-divider>
          <div class="flex mt-5">
            <feather-icon icon="AlertCircleIcon"></feather-icon>
            <p class="ml-5">
              Te recomendamos guardar la llave de 16 caracteres <strong>{{secret}}</strong> en un lugar seguro, como una aplicación de administración de contraseñas, en caso de que requieras reestablecer el segundo factor de autenticación. 
            </p>
          </div>
        </div>
      </div>

      <!-- DISEÑO ANTERIOR DE 2FA -->
        <!-- <div class="vx-row">
          <div class="vx-col w-full">
            <p class="mb-4">Para activarlo, sigue los siguientes pasos:</p>
            <p class="mb-2"><strong>1.</strong> Instala una aplicación de autenticación de dos factores en tu smarthphone o tablet.
              Te recomendamos <strong>Google Authenticator</strong> el cual podrás descargar en las siguientes ligas
              dependiendo el sistema operativo de tu teléfono:</p>
            <p class="mb-4 ml-4">
              <strong>Android:</strong><br>
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                rel="noopener noreferrer"
                target="_blank">
                https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2
              </a>
            </p>
            <p class="mb-4 ml-4">
              <strong>iPhone:</strong><br>
              <a
                href="https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8"
                rel="noopener noreferrer"
                target="_blank">
                https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8
              </a>
            </p>
            <p class="mb-1"><strong>2.</strong> Después de instalar la aplicación, deberás configurarla para que funcione con RedGirasol.
              Para lograrlo, escanea el siguiente código QR o escribe la siguiente llave de 16 caracteres: <strong>{{secret}}</strong>.</p>
          </div>
          <div class="vx-col w-full mb-2 mt-1">
            <img :src="qr" style="width:200px;" alt="qr">
          </div>

          <div class="vx-col w-full">
            <p class="mb-4"><strong>3.</strong> Es importante que <strong>almacenes en un lugar seguro</strong> la llave de 16 caracteres <strong>({{secret}})</strong>
              ya que la vas a requerir para reestablecer el segundo factor de autenticación en caso de que pierdas acceso al mismo por algún motivo.
              Te recomendamos descargar una aplicación de administración de contraseñas para hacerlo.
            </p>
          </div>

          <div class="vx-col w-full">
            <p class="mb-4"><strong>4.</strong> Después de escanear el código QR o de ingresar la llave de 16 caracteres, se te dará un código de 6 dígitos.</p>
          </div>
        </div>

        <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-1 mb-5" color="danger">
          <span class="font-regular">{{errorMssg}}</span>
        </vs-alert>

        <div class="vx-row mb-5">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
            <vs-input
              class="w-full"
              label="Por favor ingrésalo a continuación:"
              type="text"
              maxlength="6"
              v-model.lazy="authCode"
              v-validate="'required|numeric'"
              :danger="hasError('authCode')"
              :danger-text="errorText('authCode')"
              :success="isSuccess('authCode')"
              placeholder="Código de 6 dígitos"
              name="authCode"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center">
              <vs-button @click="enable2Fa" :disabled="getting">Habilitar</vs-button>
            </div>
          </div>
        </div> -->
      <!--END DISEÑO ANTERIOR DE 2FA -->

    </vx-card>
    <!-- END DISABLE CARD -->

    <!-- COMPLETITION ENABLED NOTICE CARD -->
    <vx-card class="mt-5" v-if="showCompletedNotice && completedEnabled">
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <p class="card-sub-title"><span>Habilitado</span>.</p>
        </div>
      </div>
      <vs-alert icon-pack="feather" icon="icon-check-square" class="mb-5" color="success">
        <span class="font-regular">El Segundo Factor de Autenticación ha sido <strong>habilitado</strong> exitosamente.</span>
        <span v-if="user.role === 'investor'"> Podrás acceder a las operaciones de abono, retiro y cambio de contraseña.</span>
      </vs-alert>
      <div class="vx-row">
        <!-- <div class="vx-col w-full mb-base">
          <p>¿Olvidaste guardar tu llave de 16 caracteres? Tu llave es <strong>{{secret}}</strong>.
            Recuerda que es importante que la almacenes en un lugar seguro ya que la vas a requerir para reestablecer el
            segundo factor de autenticación en caso de que pierdas acceso al mismo por algún motivo.</p>
        </div> -->
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button @click="finishProcess">Finalizar</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END COMPLETITION ENABLED NOTICE CARD -->

    <!-- COMPLETITION DISABLED NOTICE CARD -->
    <vx-card class="mt-5" v-if="showCompletedNotice && completedDisabled">
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <p class="card-sub-title">Tu Segundo Factor de Autenticación ha sido <span>deshabilitado</span>.</p>
        </div>
      </div>
      <vs-alert icon-pack="feather" icon="icon-check-square" class="mb-5" color="success">
        <span class="font-regular">El Segundo Factor de Autenticación ha sido <strong>deshabilitado</strong> exitosamente.
          Siempre podrás habilitarlo de nueva cuenta.</span>
      </vs-alert>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button type="border" @click="finishProcess" >Finalizar</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END COMPLETITION DISABLED NOTICE CARD -->

    <load-error v-if="failed" />
  </div>
</template>

<script>
export default {
  name: "Auth2Fa",
  data(){
    return {
      isMounted: false,
      currentPassword: null,
      authCode: null,
      prevSecretCode: null,
      emailCode: null,
      secret: null,
      enabled: false,
      getting: false,
      qr: null,
      errorMssg: null,
      completedEnabled: false,
      completedDisabled: false,
      emailSent: false,
      completed: false,
      failed: false,
      currentFlow: null,

      banks: [],
      states: [],
      playStoreLogo: require('@assets/images/figs/google-play.png'),
      appleStoreLogo: require('@assets/images/figs/apple-store.png'),
      copied: null,
    }
  },
  created() {
    // definicion de constantes
    this.DEFAULT_FLOW = 1;
    this.SECRET_CODE_FLOW = 2;
    this.RECOVERY_EMAIL_FLOW = 3;
  },
  async mounted() {
    this.isMounted = false
    this.showLoading(true)
    await this.get2FaData();
    this.showLoading(false)
    this.currentFlow = this.RECOVERY_EMAIL_FLOW;
    this.isMounted = true
  },
  computed: {
    onDefaultFlow(){
      return this.currentFlow === this.DEFAULT_FLOW;
    },
    onSecretCodeFlow(){
      return this.currentFlow === this.SECRET_CODE_FLOW;
    },
    onRecoveryEmailFlow(){
      return this.currentFlow === this.RECOVERY_EMAIL_FLOW;
    },
    showCurrentStatus(){
      return this.isMounted && !this.completed;
    },
    showCompletedNotice(){
      return this.isMounted && this.completed;
    },
    isPersonType0And4(){
      return (this.UserPersonType === 0 || this.UserPersonType === 4)
    },
    isPersonType1(){
      return this.UserPersonType === 1
    },
    isPersonType2(){
      return this.UserPersonType === 2
    },
    isPersonType3(){
      return this.UserPersonType === 3
    }
  },
  methods: {
    async get2FaData(){
      try {
        const res = await axios.get('/api/auth/mfa/2fa/qr');
        this.secret = res.data.secret;
        this.qr = res.data.qr_image;
        this.enabled = res.data.enabled;
        this.getting = false;
      }
      catch (error) {
        this.failed = true
        this.catchError(error);
      }
    },
    async disable2Fa(){
      this.errorMssg = null
      if(!this.verifyVarNotEmpty(this.currentPassword)){
        this.errorMssg = "Por favor ingresa todos los datos."
        return
      }
      if(this.verifyVarNotEmpty(this.authCode, 6)){
        this.getting = true
        this.showLoading(true)
        try {
          let payload = { password: this.currentPassword, code: this.authCode };
          this.injectAccountMetadataToPayload(payload);
          await axios.post('/api/auth/mfa/2fa/disable', payload);

          this.completed = true
          this.completedDisabled = true
        }
        catch (error) {
          this.catchError(error);
        }
        this.getting = false
        this.showLoading(false)
      }
      else{
        this.errorMssg = 'Verifica que tu código sea de 6 dígitos.'
      }
    },
    async disable2FaWithSecretCode(){
      this.errorMssg = null
      if(!this.verifyVarNotEmpty(this.currentPassword)){
        this.errorMssg = "Por favor ingresa todos los datos."
        return
      }
      if(this.verifyVarNotEmpty(this.prevSecretCode, 16)){
        this.getting = true
        this.showLoading(true)
        try {
          let payload = { password: this.currentPassword, secret: this.prevSecretCode };
          this.injectAccountMetadataToPayload(payload);
          await axios.post('/api/auth/2fa/disable-with-secret', payload);

          this.completed = true
          this.completedDisabled = true
        }
        catch (error) {
          this.catchError(error);
        }
        this.getting = false
        this.showLoading(false)
      }
      else{
        this.errorMssg = 'Verifica que la llave ingresada sea de 16 caracteres.'
      }
    },
    async enable2Fa(){
      this.errorMssg = null
      if(this.authCode && this.authCode.trim() && this.authCode.length === 6){
        this.showLoading(true)
        try {
          let payload = {user_role:this.UserRole, secret: this.secret, code: this.authCode};
          this.injectAccountMetadataToPayload(payload);
          await axios.post('/api/auth/mfa/2fa/enable', payload);

          this.completed = true
          this.completedEnabled = true
        }
        catch (error) {
          this.catchError(error);
        }
        this.getting = false
        this.showLoading(false)
      }
      else{
        this.errorMssg = 'Verifica que tu código sea de 6 dígitos'
      }
    },
    async sendCodeEmail(){
      this.errorMssg = null
      this.getting = true
      this.showLoading(true)
      try {
        let payload = {};
        this.injectAccountMetadataToPayload(payload);
        await axios.post('/api/auth/mfa/2fa/send-code', payload);
        this.emailSent = true;
      }
      catch (error) {
        this.catchError(error);
      }
      this.getting = false
      this.showLoading(false)
    },
    async disable2FaWithEmailCode(){
      this.errorMssg = null
      if(!this.verifyVarNotEmpty(this.currentPassword)){
        this.errorMssg = "Por favor ingresa todos los datos."
        return
      }
      if(this.verifyVarNotEmpty(this.emailCode, 6)){
        this.getting = true
        this.showLoading(true)
        try {
          let payload = { password: this.currentPassword, email_code: this.emailCode };
          this.injectAccountMetadataToPayload(payload);
          await axios.post('/api/auth/2fa/disable-with-code', payload);
          this.completed = true
          this.completedDisabled = true
        }
        catch (error) {
          this.catchError(error);
        }
        this.getting = false
        this.showLoading(false)
      }
      else{
        this.errorMssg = 'Verifica que el código ingresado sea de 6 caracteres.'
      }
    },
    showDefaultFlow(){
      this.resetVars()
      this.currentFlow = this.DEFAULT_FLOW;
    },
    showSecretCodeFlow(){
      this.resetVars()
      this.currentFlow = this.SECRET_CODE_FLOW;
    },
    async showRecoveryEmailFlow(){
      this.resetVars()
      this.currentFlow = this.RECOVERY_EMAIL_FLOW;
    },
    async finishProcess(){
      switch (this.user.role) {
        case "investor":
          await this.$router.replace({name: 'seguridadInversionista'});
          break;
        case "client":
          await this.$router.replace({name: 'seguridadSolicitante'});
          break;
        default:
          break;
      }
    },
    verifyVarNotEmpty(str, len = null){
      const notEmpty = (str && str.trim())
      if(notEmpty && len){
        return str.length === len
      }
      return notEmpty
    },
    resetVars(){
      this.currentPassword = null
      this.prevSecretCode = null
      this.emailCode = null
      this.authCode = null
      this.errorMssg = null
    },
    catchError(error){
      if (typeof error.response.data.message !== 'undefined') {
        this.errorMssg = error.response.data.message
      }
      else {
        this.errorMssg = 'Ha ocurrido un error, inténtalo de nuevo más tarde. Ponte en contacto con nosotros si el problema persiste.'
      }
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    async copyToClipboard (){
      const el = document.createElement('textarea');
      el.value = this.secret;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copied = true;
    }
  }
}
</script>